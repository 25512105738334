<template>
  <div class="page-container">
    <div class="header-body">
      <el-card>
        <el-row :gutter="24">
          <el-col :span="3">
            <el-select
              v-model="queryInfo.schoolId"
              placeholder="请选择院校"
            >
                    <span v-for="(item, index) in schoolList" :key="index">
                      <el-option
                        :label="item.schoolName"
                        :value="item.id"
                      ></el-option>
                    </span>
            </el-select>
          </el-col>
          <el-col :span="3">
            <el-select
              v-model="queryInfo.majorId"
              placeholder="请选择专业"
            >
                    <span v-for="(item, index) in majorList" :key="index">
                      <el-option
                        :label="item.majorName"
                        :value="item.id"
                      ></el-option>
                    </span>
            </el-select>
          </el-col>
          <el-col :span="3">
            <el-date-picker
              type="date"
              placeholder="开始日期"
              value-format="yyyy-MM-dd"
              v-model="queryInfo.startTime"
              style="width: 100%"
            ></el-date-picker>
          </el-col>
          <el-col class="line" :span="1" style="text-align: center"
          >-</el-col
          >
          <el-col :span="3">
            <el-date-picker
              type="date"
              placeholder="结束日期"
              value-format="yyyy-MM-dd"
              v-model="queryInfo.endTime"
              style="width: 100%"
            ></el-date-picker>
          </el-col>
          <el-col :span="4">
            <div class="grid-content bg-purple">
              <el-input
                placeholder="请输入姓名"
                class="input-with-select"
                clearable
                v-model.trim="queryInfo.name"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="searchHandle"
                ></el-button>
              </el-input>
            </div>
          </el-col>
          <el-col :span="3">
            <el-button  @click="resetQuery">重置</el-button>
          </el-col>
          <el-col :span="3">
            <el-button  @click="exportOrder" type="primary">导出</el-button>
          </el-col>
        </el-row>
      </el-card>
    </div>
    <div class="table-body">
      <el-card>
        <el-table :data="studentList" style="width: 100%" border stripe>
          <el-table-column type="index" label="ID" width="50">
          </el-table-column>
          <el-table-column prop="orderNum" label="订单号"> </el-table-column>
          <el-table-column prop="name" label="姓名"> </el-table-column>
          <el-table-column prop="schoolName" label="报名院校">
          </el-table-column>
          <el-table-column prop="majorName" label="报名专业"> </el-table-column>
          <el-table-column prop="price" label="报名费"> </el-table-column>
          <el-table-column prop="gmtCreate" label="创建时间"> </el-table-column>
          <!-- <el-table-column label="操作" width="150">
            <template v-slot="scoped">
              <el-button type="text" @click="deleteOrder(scoped.row.id, scoped.row.name)">删除</el-button>
            </template>
          </el-table-column> -->
        </el-table>
      </el-card>
    </div>
    <div class="pagination-body">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { getOrder, deleteSignupOrder, getSchoolList, getMajorBySchoolId } from '@/api/api'
import { host } from '@/utils/service/url'
import axios from 'axios'

export default {
  data () {
    return {
      schoolList: [],
      majorList: [],
      studentList: [],
      previewDialog: false,
      queryInfo: {
        currentPage: 1,
        pageSize: 10,
        orderNum: '',
        name: '',
        schoolId: '',
        majorId: '',
        startTime: '',
        endTime: ''
      },
      total: 0
    }
  },
  created () {
    this.getOrder()
    this.getSchoolList()
  },
  watch: {
    'queryInfo.schoolId' (data) {
      this.getMajorBySchoolId(data)
    }
  },
  methods: {
    /* 获取订单列表 */
    getOrder () {
      getOrder(this.queryInfo).then(res => {
        this.studentList = res.data.list
        this.total = res.data.total
      })
    },
    /* 删除订单 */
    deleteOrder (id, name) {
      this.$confirm(`是否永久删除【${name}】的订单`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          deleteSignupOrder(id).then(res => {
            this.getOrder()
            this.$message({
              type: 'success',
              message: '删除成功'
            })
          })
        })
        .catch(() => {
          this.$message({
            type: 'warning',
            message: '已取消删除'
          })
        })
    },
    /* 获取学校列表 */
    getSchoolList () {
      getSchoolList({
        currentPage: 1,
        pageSize: 30
      }).then(res => {
        this.schoolList = res.data.list
      })
    },
    /* 根据学校id获取专业列表 */
    getMajorBySchoolId (schoolId) {
      if (schoolId) {
        this.queryInfo.majorId = ''
        getMajorBySchoolId(schoolId).then(res => {
          this.majorList = res.data
        })
      }
    },
    /* 导出订单 */
    exportOrder () {
      axios({
        url: host + 'order/exportOrder',
        method: 'post',
        responseType: 'blob',
        data: this.queryInfo,
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: window.sessionStorage.getItem('token')
        }
      }).then(res => {
        const blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8'
        })
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = `student${Math.floor(Math.random() * 10000)}.xlsx`
        document.body.appendChild(a)
        a.click()
      })
    },
    /* 重置 */
    resetQuery () {
      this.queryInfo = {
        currentPage: 1,
        pageSize: 10,
        orderNum: '',
        name: '',
        schoolId: '',
        majorId: '',
        startTime: '',
        endTime: ''
      }
      this.getOrder()
    },
    /* 根据订单号搜索 */
    searchHandle () {
      this.queryInfo.currentPage = 1
      this.getOrder()
    },
    /* 分页选择 */
    handleSizeChange (size) {
      this.queryInfo.pageSize = size
      this.getOrder()
    },
    handleCurrentChange (size) {
      this.queryInfo.currentPage = size
      this.getOrder()
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  .pagination-body {
    .el-pagination {
      text-align: center;
      padding: 20px 0;
    }
  }
}
</style>
